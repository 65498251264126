.text-container {
    padding-left: 25px;
    width: 90%;
  }
  
  .title {
    font-size: 18px;
    font-family: "Inter-Semibold";
    margin-top:15px;
    line-height: 24px;
  }
  
  .desc {
    font-size: 16px;
    line-height: 26px;
    color: #282828;
    max-height: 300px;
    overflow-y: scroll;

  }                                                                                                                                          
  .header-title{
    font-size: 20px;
    font-family: 'Inter-Semibold';
  }      
  
  .container{
    padding:20px;
    max-width: 600px;
    margin:0px auto;
    background-color: #fff;
  }

  .grey-box{
    padding:18px;
    background: #F8F8F8;
    border-radius: 0.501333px;
    margin-top:30px;
    /* max-height: 220px; */
    /* overflow: scroll; */
  }

  .grey-box-title{
    font-size: 16px;
    font-family: 'Inter-SemiBold';
    margin-bottom: 15px;
  }

  .button-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* position: absolute;
    bottom:0; */
    width: 100%;
    margin-top:20px;
    /* padding:20px */

}

.terms-condition{
    display: flex;
    align-items: flex-start;
}

.terms-condition-text{
    margin-left: 8px;
    line-height: 23px;
    font-size: 16px;
    color:#282828
}