.top-card{
    display: flex;
    width:100%;
    /* margin:20px auto; */
    /* padding:10px; */
    justify-content: space-between;
}

.subtitle{
    font-size: 13px;
    font-family: 'Inter-Medium';
}

.heading{
    font-size: 20px;
    font-family: 'Inter-Bold';
}

.title{
    font-size: 18px;
    font-family: 'Inter-Bold';
}
.img{
    width:100%;
    /* height:190px */
}

.text-overlay{
    position: absolute;
    text-align: center;
    color: #fff;
}

.desc{
    font-size: 14px;
    line-height: 24px;
}

.solid-btn{
    padding: 0px !important;
}

.text-filed{
    padding:0px !important;
    background-color:#fff !important;
    margin-top:15px;
    width:100% !important;
    border-radius: 2px !important;
}

.text-filed :global(.toqio-form-input-container){
    border-radius: 0px !important;
}

.text-filed input{
    /* background:'red' !important; */
    background-color:#fff !important
    /* margin-top:-40px */
}