

.container{
    width:100%;
    margin:0px auto;
    overflow: hidden;
}

.img{
    width:40%;
    /* height:190px */
}

.grey-box{
    background-color: #F8F8F8;
    padding-left: 20px;
    padding-right:20px;
    padding-bottom: 20px;
}

.sub-img{
    width:60px;
    height:60px;
    margin:20px auto;
}

.title{
    font-size: 24px;
    font-family: Inter-Bold;
    line-height: 30px;
}

.subtitle{
    font-size: 12px;
    font-family: Inter-Bold;
}

.desc{
    font-size: 16px;
    line-height: 26px;
}



.sub-desc{
    padding:15px;
    border-top:1px solid #F0F0F0;
    border-bottom:1px solid #F0F0F0;
    font-size: 15px;
    margin-top:20px
}