.top-card{
    display: flex;
    width:100%;
    margin:0px auto;
    /* padding:10px; */
    justify-content: space-between;
}

.subtitle{
    font-size: 13px;
    font-family: 'Inter-Medium';
}

.heading{
    font-size: 20px;
    font-family: 'Inter-Bold';
}

.title{
    font-size: 18px;
    font-family: 'Inter-Bold';
}
.img{
    width:100%;
    /* height:190px */
}

.text-overlay{
    position: absolute;
    text-align: center;
    color: #fff;
}

.desc{
    font-size: 14px;
    line-height: 24px;
}