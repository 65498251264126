.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 10px; */
    border-top: 2px solid #F0F0F0;
    /* padding:10px; */
    padding-left: 20px;
    padding-right: 20px;
    height:60px
      
    /* align-items: center; */
}

.title{
    font-size: 16px;
    font-family: 'Inter-Bold';
}

.desc{
    font-size: 16px;
    line-height: 22px;
    font-family: 'Inter-Regular';
}

.title-head{
    font-family: 'Inter-SemiBold';
    font-size: 18px;
    line-height: 26px;
}

.desc-head{
    font-size: 14px;
    line-height: 26px;
}