.title{
    font-size: 24px;
    font-family: Inter-Bold;
    line-height: 30px;
}



.desc{
    font-size: 16px;
    line-height: 26px;
}


.top-card{
    width: 100%;
    max-width: 600px !important;
    overflow: hidden;
}