

.container{
    width:100%;
    max-width: 600px;
    margin:0px auto;
    overflow: hidden;
}

.img{
    width:100%;
    /* height:190px */
}



.title{
    font-size: 22px;
    font-family: Inter-Bold;
}

.subtitle{
    font-size: 18px;
    font-family: Inter-Bold;
}

.desc{
    font-size: 16px;
    line-height: 26px;
}

.sub-img{
    width:100%;
    margin:20px auto;
    height:30%;

}

.sub-desc{
    padding:15px;
    border-top:1px solid #F0F0F0;
    border-bottom:1px solid #F0F0F0;
    font-size: 15px;
    margin-top:20px
}