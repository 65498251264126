/* here we defined the global fonts of the application */

@font-face {
  font-family: "Inter-Black";   /*Can be any text*/
  src: local("Inter-Black"),
    url("./fonts/Inter-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Bold";   /*Can be any text*/
  src: local("Inter-Bold"),
    url("./fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-ExtraBold";   /*Can be any text*/
  src: local("Inter-ExtraBold"),
    url("./fonts/Inter-ExtraBold.ttf") format("truetype");
}


@font-face {
  font-family: "Inter-ExtraLight";   /*Can be any text*/
  src: local("Inter-ExtraLight"),
    url("./fonts/Inter-ExtraLight.ttf") format("truetype");
}


@font-face {
  font-family: "Inter-Light";   /*Can be any text*/
  src: local("Inter-Light"),
    url("./fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Medium";   /*Can be any text*/
  src: local("Inter-Medium"),
    url("./fonts/Inter-Medium.ttf") format("truetype");
}


@font-face {
  font-family: "Inter-Regular";   /*Can be any text*/
  src: local("Inter-Regular"),
    url("./fonts/Inter-Regular.ttf") format("truetype");
}


@font-face {
  font-family: "Inter-SemiBold";   /*Can be any text*/
  src: local("Inter-SemiBold"),
    url("./fonts/Inter-SemiBold.ttf") format("truetype");
}


@font-face {
  font-family: "Inter-Thin";   /*Can be any text*/
  src: local("Inter-Thin"),
    url("./fonts/Inter-Thin.ttf") format("truetype");
}


body {
  margin: 0;
  font-family:Inter-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
}
