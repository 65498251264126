
.container {
    width:100%;
    max-width: 600px;
    margin:0px auto;
    justify-content:center;
    align-items:center;
    overflow: hidden;
  }

  .crousel-image{
    width: 100%;
     height: 260px
  }

  .crousel-image-small{
    width: 100%;
     height: 150px
  }

  .crousel-image-earth{
    /* width:45%; */
    height: 150px;
    margin:0px auto;
    background-color: #eee;
    display: flex;
    background-size: 150px;
    
  }

  .small-container{
    width:47%;
    /* margin:20px auto; */
    margin-top: 20px;
    justify-content:center;
    align-items:center;
    /* margin-top:30px; */
    overflow: hidden;
  }

  .title{
    font-size: 22px;
    font-family: Inter-Bold;
    margin-left:0px;
    height:50px;
    /* align-items: center; */
    /* display: flex; */
}

.desc{
  font-size: 14px;
    margin-left:0px;
}


.title-small{
    font-size: 16px;
    font-family: Inter-Bold;
    margin-left:10px;
    height:40px;
}

@media only screen and (max-width: 450px) { 
  .crousel-image{
    width: 100%;
     height: 160px
  }

  .crousel-image-small{
    width: 100%;
     height: 100px
  }
  .crousel-image-earth{
    /* width:45%; */
    height: 100px;
    margin:0px auto;
    display: flex;
    
  }


  
}
