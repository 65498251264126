.container{
    display: flex;
    justify-content: space-between;

    border-bottom: 2px solid #F0F0F0;
   
    height:60px;
      
    align-items: center;
}

.title{
    font-size: 18px;
    font-family: 'Inter-Medium';
}