.container{
  width:100%;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height:100vh
  }

  .img{
    width:70%;
    margin:0px auto
    /* max-height:400px; */

  }

  .title{
    font-size: 20px;
    text-align: center;
    font-family: 'Inter-SemiBold';
  }
  .desc{
    font-size: 16px;
    text-align: center;
    line-height: 20px;
  }

  .text-container{
    padding: 20px;
    justify-content:center;
    align-items:center;
    display:flex;
    width:70%;
    flex-direction:column;
    background-color: #fff;
  }

  .res-container{
    max-width: 600px !important;
    margin:0px auto !important;
    padding-left:10px !important;
    padding-right:10px !important;
    margin-bottom: 20px !important;
    /* background-color: #fff !important; */
  }

  @media only screen and (max-width: 450px) { 
    .title{
      font-size: 24px;
      text-align: center;
      font-family: 'Inter-SemiBold';
      margin-top:-5px
    }

    
  .img{
    width:100%;
    margin:0px auto
    /* max-height:400px; */

  }
  .res-container{
    max-width: 600px !important;
    margin:0px auto !important;
    padding-left:0px !important;
    padding-right:0px !important;
    margin-bottom: 20px !important;
    width: 100%;
  }

  .text-container{
    padding: 0px;
    justify-content:center;
    align-items:center;
    display:flex;
    width:100%;
    flex-direction:column
  }


  }

 
  