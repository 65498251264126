.title{
    font-size: 18px;
    font-family: 'Inter-Medium';
    /* line-height: 22px; */
}

.desc{
    font-size: 16px;
    font-family: 'Inter-Regular';
    line-height: 20px;
}