.next-button {
  background-color: transparent;
  border: none;
  /* width: 40px; */
  /* height: 40px; */
}

.crousel-item {
  width: 100%;
  /* max-height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 20px;
  font-family: "Inter-Bold";
  text-align: center;
}

.desc {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.crousel-dot {
  border-radius: 4px;
  margin: 8px;
  width: 8px;
  height: 8px;
}

.crousel-dot-active {
  background-color: #18a886;
}

.crousel-dot-inactive {
  background-color: #f0f0f0;
}


.crousel-container {
  width:100%;
  max-width: 600px;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  background-color: #fff;
  /* margin-top: -40px; */
}

.crousel-image {
  width: auto;
  /* height:300px */
  /* height:300px */
}

.text-container {
  padding: 20px;
  width: 80%;
}

.button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
}
.crousel-image-2 {
  width: 40%;
  height:220px !important

}

.link-text{
  font-size: 16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
/* } */


@media only screen and (max-width: 500px) { 
  .crousel-image {
    width: 100%;
    /* height:300px !important */
  
  }

}
@media only screen and (max-width: 450px) { 
 

  .title {
    font-size: 18px;
    font-family: "Inter-Bold";
    text-align: center;
  }
  .desc {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .crousel-image-2 {
    width: 100%  !important;
    height:auto !important;
  
  }

  .link-text{
    font-size: 13px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .crousel-container {
    max-width: 600px;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    margin-top: 15px;
  }

  .text-container {
    padding: 0px;
    width: 90%;
    margin-top:20px
  }

}
