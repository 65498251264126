/* here we defined the global css of the application */

.App {
  text-align: center;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #89cce2; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #89cce2; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #89cce2; 
}

.outline-btn {
  min-width:30%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom:8px;
  text-align: center;
  color: #89cce2;
  border-radius: 20px;
  border: 2px solid #89cce2;
}

.solid-circle-btn {
  width: 50px !important;
  height: 50px !important;
  text-align: center;
  background-color: #89cce2 !important;
  border-radius: 25px !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  border-width: 2px !important;
  cursor: pointer;
}

.solid-btn {
  min-width:30%;
  padding-left: 20px !important;
  padding-right: 20px !important;;
  padding-top: 8px !important;
  padding-bottom:8px !important;
  text-align: center !important;
  background-color: #89cce2;
  border-radius: 25px !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  border: 2px solid #89cce2 !important;
  cursor: pointer;
  color:#fff;
 
}

.pointer{
  cursor:pointer
}

.res-container{
  max-width: 600px !important;
  margin:0px auto !important;
  padding-left:10px !important;
  padding-right:10px !important;
  margin-bottom: 20px !important;
  /* background-color: #fff !important; */
}

.opt-out .toqio-modal .toqio-modal-header-wrapper{
  padding:0px;
  padding-right: 0px !important;
}

.cc-res-container{
  max-width: 600px !important;
  margin:0px auto !important;
}
.disabled-btn{
  background-color: grey !important;
  color:#fff !important;
  border: 2px solid grey !important;
 
}

.disabled-btn .toqio-basic-text.toqio-button-text{
  color:#000 !important
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}





.font-10{
  font-size: 10px;
}

.font-11{
  font-size: 11px;
}

.font-12{
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}


.font-24 {
  font-size: 24px;
}

.font-28 {
  font-size: 28px;
}

.font-30 {
  font-size: 30px;
}


.mt-1 {
  margin-top: 10px;
}

.m-1 {
  margin: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.ml-2{
  margin-left: 20px;
}

.ml-1{
  margin-left: 10px;
}

.toqio-modal{
  /* background-color: rgba(0,0,0,0.4) !important; */
  /* opacity: 0.5 !important; */
  /* width:200px !important */
  /* width: ; */
 
}

.mr-1{
  margin-right: 10px;
}

.m-5{
  margin:5px
}
.p-20 {
  padding: 20px;
}

.p-24 {
  padding: 24px;
}
.p-10 {
  padding: 10px;
}
.w-90{
  width:90%
}

.w-40{
  width:40%
}

.w-80{
  width:80%
}


.w-100{
  width:100%
}

.mb-2{
  margin-bottom: 20px !important;
}

.row-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.divider{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 1px;
  background: #F0F0F0;

}

.border-bottom{
border-bottom: 1px solid #F0F0F0;
}
.row-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-start{
  display: flex;
  justify-content: center;
}

.column-center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.relative{
  position: relative;
}

.text-center{
  text-align: center;
}

.toqio-modal-box{
  background-color: transparent !important;
}

.toqio-modal{
  max-width: 540px !important;
  padding: 0px !important;
  margin-top:80px !important
  /* margin:0px !important; */
  
}

.crousel-container-learn::-webkit-scrollbar {
  display: none !important;
}

.primary-color{
  color: #89cce2;
}

.toqio-top-bar{
  padding-left: 0px !important;
  padding-right: 2px !important;
}
.crousel-container-learn{
  width:90%;
  margin:0px auto;
  display: flex;
  justify-content:center;
  align-items:center;
  overflow-x: auto;
  padding-bottom: 2px;
}

.toqio-modal-icon{
  margin:10px !important
}

.toqio-modal-header-wrapper{
  padding-left:0px !important;
  padding-top:0px !important
}
.reactour__popover{
  background-color: transparent !important;
  box-shadow: none !important;
  justify-content: center;
  align-items: flex-end;
  min-width: 100%;
  min-height: 100%;
  /* max-width: 600px !important; */
  display: flex;
  transform:unset !important;

}

.toqio-form-input-container{
  background:#fff !important;
  border:1px solid #BCBCBC !important
}

.crousel-dot{
  border-radius:4px;
  margin:8px;
  width:8px;
  height:8px;

}

.button-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:40px
}


.crousel-dot {
  border-radius: 4px;
  margin: 8px;
  width: 8px;
  height: 8px;
}

.crousel-dot-active {
  background-color: #89cce2;
}

.crousel-dot-inactive {
  background-color: #f0f0f0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media(max-wdth < 450){
  .toqio-modal{
    max-width: 300px !important;
    padding: 0px !important;
    margin-top:50px !important
    /* margin:0px !important; */
    
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
