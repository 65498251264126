.container{
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #F0F0F0;
     padding:15px;
     /* width:100% */

      
    /* align-items: center; */
}

.title{
    font-size: 15px;
    font-family: 'Inter-Regular';
    margin-left: 10px;
}