

.graph{
    width:90%;
    height:60%;
    position: relative;
    margin-top:20px;
    justify-content: center;
    align-items: center;
    display: flex;
   
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;    /* background-color: red; */
    border-radius: 6px;
}

.container{
    width:100%;
    max-width: 600px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    /* margin:0px auto */
    /* height:200px; */
    /* background-color: red; */
}

.crousel-container {
    width:100%;
    margin:0px auto;
    justify-content:center;
    align-items:center;
  }
  
  .crousel-image{
    width: 100%;
     height: 120px
  }
  
  .text-container{
    width: 90%;
    padding-left:10px;
    font-family: 'Inter-Bold';
  }
  

  .crousel-item{
    width: 90%;
    height: 200px;
    overflow: hidden;
    /* margin-left:120px */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
}

.title{
    font-size: 16px;
}

.action-card{
    display: flex;
    justify-content: space-between;
    width:100%;
    margin:0px auto;
    overflow: hidden;
}