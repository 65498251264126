.text-container {
  padding-left: 25px;
  width: 90%;
}

.title {
  font-size: 20px;
  font-family: "Inter-Bold";
}

.desc {
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 50px;
}

.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px;
    width: 95%;
    height: 28px;
    background: #F8F8F8;
    border-radius: 4px;


}

.header-title{
    font-size: 14px;
    font-family: "Inter-Bold";
}
.table-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px;
    width: 95%;
    min-height: 28px;
}

.table-row-item{
    font-size: 14px;

}

.divider{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 2px;
    background: #F0F0F0;

}

.table{
    margin-bottom: 20px;
}

.button-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;

}

