


.top-card{
    padding:20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F8F8F8;
}


.heading{
    font-size: 24px;
    font-family: 'Inter-Bold';
    margin-top: 20px;

}

.sub-title{
    font-size: 18px;
    font-family: 'Inter-SemiBold';
    color: #686868;
}

.grey-box{
    background-color: #F8F8F8;
    justify-content: space-around;
    display: flex;
    width:100%;
    padding-top:20px;
    padding-bottom: 20px;
}

.transaction-type{
    padding:20px;
    border-bottom:1px solid #F0F0F0;
    margin-left:20px;
    margin-right: 20px;
}